.main {
    text-align: center;
}

.title {
    background-color: rgba(15, 15, 90, 0.938);
    color: white;
    padding: 20px;
    margin-top: 10px;
    letter-spacing: 2px;
    font-weight: normal;
}

li {
    text-align: left;
    border: 0;
    padding: 5px;
    list-style-type: disc;
}

img {
    position: relative;
    width: 100%;
    overflow: hidden;
    /* 이미지의 크기가 크면 나머지 부분 삭제 */
}

.img-product {
    width: 70vw;
    max-width: 600px;
    display: block;
    margin: 0px auto;
}

th {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.dropdown-model {
    background-color: white;
}

.img-parts {
    /* position: absolute; */
    top: 0;
    left: 0;
    transform: translate(50, 50);
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
    max-width: 100px;
    max-height: 100px;
}

.img-filter {
    /* position: absolute; */
    top: 0;
    left: 0;
    /* transform: translate(70, 50); */
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
    margin: auto;
    /* max-width: 200px;
    max-height: 200px; */
}

td {
    text-align: center;
    vertical-align: middle;
}

.img-ui {
    margin: 15px;
    width: 95%;
}

.text-roprocess {
    text-align: left;
    margin: 20px;
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url("https://hdeng13.speedgabia.com/hdro/cc/water1.jpg");
    background-size: cover;
    background-position-y: bottom; */
    /* filter: brightness(100); */
    /* background-size: cover; */
}