body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {

}



.me-auto {
  font-weight: bold;
  color: black;
}

.main-bg {
  height: 500px;
  background-image: url(/img/mainbg.jpg);
  background-size: cover;
  background-position: center;
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}

.main-copy {
  padding: 150px;
  line-height: 50px;
  text-align: left;
  font-size: x-large;
  color: white;
  /* font-weight: bold; */
}
.navbar-inverse .navbar-nav>li>a:hover {
    background-color: gray;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}

.carousel-main {
    overflow: hidden;
    position: relative;
    width: 100vw;
    height: auto;
    max-height: 400px;
}

.carousel-h3 {
    margin-bottom: 20%;
}

.link-button {
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    text-align: center;
    margin-top: 10px;
    /* padding: 0%; */
}

.main-notice {
    width: 95vw;
    margin: 10px;
    position: relative;
    text-align: left;
}

.brand {
    width: 80px;
    height: 40px;
    margin-bottom: 5px;
    /* background-image: url(/img/logo500px.png);
    background-size: contain; */
    /* border: 1px solid red;
    display: block;
    margin: 0px auto; */
}
.container-item{
    /* width: 95vw; */
}

.page-footer {

    background-color: rgb(8, 8, 68);
    color: white;

}

.container-fluid {
    padding: 30px;
    max-width: 1280px;
}

.footer-main {
    text-align: left;
    line-height: 10px;
}

.footer-text {
    padding-top: 20px;
}

.col-md-3 {
    text-align: left;
}

.list-unstyled a:link {
    color: skyblue;
}
.intro {
    margin: 10px;
}

.intro-copy {}

h4 {
    color: steelblue;
    line-height: 150%;
}

.intro li {
    list-style-type: none;
}

.kakaomap {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 50vw;
    max-height: 400px;
}

.main {
    text-align: center;
}

.title {
    background-color: rgba(15, 15, 90, 0.938);
    color: white;
    padding: 20px;
    margin-top: 10px;
    letter-spacing: 2px;
    font-weight: normal;
}

li {
    text-align: left;
    border: 0;
    padding: 5px;
    list-style-type: disc;
}

img {
    position: relative;
    width: 100%;
    overflow: hidden;
    /* 이미지의 크기가 크면 나머지 부분 삭제 */
}

.img-product {
    width: 70vw;
    max-width: 600px;
    display: block;
    margin: 0px auto;
}

th {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.dropdown-model {
    background-color: white;
}

.img-parts {
    /* position: absolute; */
    top: 0;
    left: 0;
    -webkit-transform: translate(50, 50);
            transform: translate(50, 50);
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
    max-width: 100px;
    max-height: 100px;
}

.img-filter {
    /* position: absolute; */
    top: 0;
    left: 0;
    /* transform: translate(70, 50); */
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
    margin: auto;
    /* max-width: 200px;
    max-height: 200px; */
}

td {
    text-align: center;
    vertical-align: middle;
}

.img-ui {
    margin: 15px;
    width: 95%;
}

.text-roprocess {
    text-align: left;
    margin: 20px;
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url("https://hdeng13.speedgabia.com/hdro/cc/water1.jpg");
    background-size: cover;
    background-position-y: bottom; */
    /* filter: brightness(100); */
    /* background-size: cover; */
}
.card-detail {
    margin: 10px auto;
}

.card-detail img {
    height: 50vw;
    width: auto;
    max-height: 600px;
    object-fit: cover;
}

.card-img {
    max-height: 800px;
    width: auto;
    display: block;
    margin: 0px auto;
}

ul {
    list-style: none;
    text-align: left;
}
.img-model {
    /* width: 70vw; */
    /* max-width: 1800px; */
    display: block;
    margin: 0px auto;
}

.main-menu {
    margin: 10px;
    /* border: 1px solid red; */
    text-align: center;
}

.btn-text {
    text-align: left;
}

.main-menu h5 {
    margin-top: 20px;
}

.container-a4 {
    width: 210mm;
    min-height: 297mm;
    padding: 20mm;
    /* margin: 10mm auto; */
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}

.info-customer p {
    line-height: 18px;
}

.row1 {
    text-align: center;
    margin: 10px;
}

.row2 {
    margin-top: 30px;
}

.row3 {
    margin-top: 10px;
}

.info-company tr td {
    width: 100mm;
    text-align: center;
    border-collapse: collapse;
    border: 1px solid;
    padding: 3px;
}

.table-contents th {
    padding: 3px;
    background-color: lightgray;
    border-collapse: collapse;
    border: 1px solid;
}

.table-contents tr td {
    padding: 2px;
    border-collapse: collapse;
    border: 1px solid black;
}

.table-contents li {
    font-size: small;
    line-height: 15px;
}

.tr1 {
    /* border-top: 2px dotted blue; */
}

.est2-btn{
    margin: 10px;
}
.item{
    margin: 10px;
}

.item li {
    list-style-type: disc;
    list-style-position: inside;
}
