.navbar-inverse .navbar-nav>li>a:hover {
    background-color: gray;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}

.carousel-main {
    overflow: hidden;
    position: relative;
    width: 100vw;
    height: auto;
    max-height: 400px;
}

.carousel-h3 {
    margin-bottom: 20%;
}

.link-button {
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    text-align: center;
    margin-top: 10px;
    /* padding: 0%; */
}

.main-notice {
    width: 95vw;
    margin: 10px;
    position: relative;
    text-align: left;
}

.brand {
    width: 80px;
    height: 40px;
    margin-bottom: 5px;
    /* background-image: url(/img/logo500px.png);
    background-size: contain; */
    /* border: 1px solid red;
    display: block;
    margin: 0px auto; */
}
.container-item{
    /* width: 95vw; */
}
