.img-model {
    /* width: 70vw; */
    /* max-width: 1800px; */
    display: block;
    margin: 0px auto;
}

.main-menu {
    margin: 10px;
    /* border: 1px solid red; */
    text-align: center;
}

.btn-text {
    text-align: left;
}

.main-menu h5 {
    margin-top: 20px;
}
