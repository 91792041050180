.intro {
    margin: 10px;
}

.intro-copy {}

h4 {
    color: steelblue;
    line-height: 150%;
}

.intro li {
    list-style-type: none;
}

.kakaomap {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 50vw;
    max-height: 400px;
}