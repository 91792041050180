.container-a4 {
    width: 210mm;
    min-height: 297mm;
    padding: 20mm;
    /* margin: 10mm auto; */
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}

.info-customer p {
    line-height: 18px;
}

.row1 {
    text-align: center;
    margin: 10px;
}

.row2 {
    margin-top: 30px;
}

.row3 {
    margin-top: 10px;
}

.info-company tr td {
    width: 100mm;
    text-align: center;
    border-collapse: collapse;
    border: 1px solid;
    padding: 3px;
}

.table-contents th {
    padding: 3px;
    background-color: lightgray;
    border-collapse: collapse;
    border: 1px solid;
}

.table-contents tr td {
    padding: 2px;
    border-collapse: collapse;
    border: 1px solid black;
}

.table-contents li {
    font-size: small;
    line-height: 15px;
}

.tr1 {
    /* border-top: 2px dotted blue; */
}

.est2-btn{
    margin: 10px;
}