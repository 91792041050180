.card-detail {
    margin: 10px auto;
}

.card-detail img {
    height: 50vw;
    width: auto;
    max-height: 600px;
    object-fit: cover;
}

.card-img {
    max-height: 800px;
    width: auto;
    display: block;
    margin: 0px auto;
}

ul {
    list-style: none;
    text-align: left;
}