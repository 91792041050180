.App {

}



.me-auto {
  font-weight: bold;
  color: black;
}

.main-bg {
  height: 500px;
  background-image: url(/img/mainbg.jpg);
  background-size: cover;
  background-position: center;
  filter: brightness(70%);
}

.main-copy {
  padding: 150px;
  line-height: 50px;
  text-align: left;
  font-size: x-large;
  color: white;
  /* font-weight: bold; */
}