.page-footer {

    background-color: rgb(8, 8, 68);
    color: white;

}

.container-fluid {
    padding: 30px;
    max-width: 1280px;
}

.footer-main {
    text-align: left;
    line-height: 10px;
}

.footer-text {
    padding-top: 20px;
}

.col-md-3 {
    text-align: left;
}

.list-unstyled a:link {
    color: skyblue;
}